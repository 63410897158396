<template>
  <div class="info-form-container">
    <h2>Diagnostic des comptes du défunt</h2>
    <form>
      <div class="form-group">
        <label>Adresse(s) email du défunt</label>
        <div class="email-input">
          <input type="text" v-model="localEmail" @input="updateEmail" placeholder="jacques.dimando@gmail.com" />
        </div>
        <span style="color: #98A2B3;">S'il en existe plusieurs, renseignez-les toutes en les séparant d'une
          virgule.</span>
      </div>
      <div class="form-group">
        <label>Numéro de téléphone du défunt</label>
        <div class="phone-input">
          <!-- <select v-model="countryCode" :disabled="isReadOnly"> -->
          <!-- <option value="+33">🇫🇷 +33</option> -->
          <!-- Ajoute d'autres options de code de pays ici -->
          <!-- </select> -->
          <input type="text" v-model="localPhone" @input="updatePhone" placeholder="06 20 80 90 12" />
        </div>
      </div>
      <button v-if="dossierState === 'En attente de lancement'" type="button" @click="scanRapide"
        :disabled="scanEnCours || loading || !demarchesLoaded || (!localEmail && !localPhone)">
        <span v-if="loading">Recherche des comptes en cours...</span>
        <span class="lancer-button" v-else>Lancer un scan 
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
              stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </button>
      <span v-if="loading" class="loading-text">La recherche peut prendre jusqu'à 30 secondes.</span>
    </form>
  </div>
</template>

<script>
import { logErrorToDiscord } from '@/logService';
import emitter from '@/eventBus';

export default {
  name: 'InfoForm',
  props: {
    idDossier: {
      type: String,
      required: true
    },
    dossierState: {
      type: String,
      required: true
    },
    scanEnCours: {
      type: Boolean,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    comments: {
      type: String,
      required: true
    },
    siteMappings: {
      type: Array,
      required: true
    },
    siteMappingsReady: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      localEmail: this.email,
      localPhone: this.phone,
      localComments: this.comments,
      loading: false,
      countryCode: '+33', // Code de pays par défaut
      demarchesLoaded: false // Initialisé à false
    }
  },
  created() {
    emitter.on('demarches-loaded', (status) => {
      this.demarchesLoaded = status;
    });
  },
  watch: {
    email(newVal) {
      this.localEmail = newVal;
    },
    phone(newVal) {
      this.localPhone = newVal;
    },
    comments(newVal) {
      this.localComments = newVal;
    }
  },
  methods: {
    formatFrenchPhoneNumber(phoneNumber) {
      try {
        // Enlève les espaces et les points du numéro de téléphone
        phoneNumber = phoneNumber.replace(/[\s.]+/g, '');

        // Vérifie si le numéro commence par '0' 
        if (phoneNumber.startsWith('0')) {
          // Remplace le premier '0' par '33'
          return '33' + phoneNumber.slice(1);
        }

        // Vérifie si le numéro commence par '+33' 
        if (phoneNumber.startsWith('+33')) {
          // Enlève le '+' pour obtenir le format '33X.XX.XX.XX'
          return phoneNumber.slice(1);
        }

        // Retourne le numéro original si les conditions ne sont pas remplies
        return phoneNumber;
      } catch (error) {
        console.log(error);
      }
    },
    updateEmail() {
      this.$emit('update-email', this.localEmail);
    },
    updatePhone() {
      this.$emit('update-phone', this.localPhone);
    },
    updateComments(comments) {
      this.localComments = comments;
      this.$emit('update-comments', this.localComments);
    },
    getServiceDomain(service) {
      const domains = {
        facebook: 'facebook.com',
        google: 'google.com',
        instagram: 'instagram.com',
        linkedin: 'linkedin.com',
        twitter: 'twitter.com',
        microsoft: 'microsoft.com',
        yahoo: 'yahoo.com',
        skype: 'skype.com',
        whatsapp: 'whatsapp.com',
        telegram: 'telegram.org',
        viber: 'viber.com',
        kakaotalk: 'kakaocorp.com',
        ok: 'ok.ru',
        zalo: 'zalo.me',
        line: 'line.me',
        flipkart: 'flipkart.com',
        bukalapak: 'bukalapak.com',
        jdid: 'jdid.com',
        apple: 'apple.com',
        ebay: 'ebay.com',
        flickr: 'flickr.com',
        foursquare: 'foursquare.com',
        github: 'github.com',
        gravatar: 'gravatar.com',
        lastfm: 'last.fm',
        myspace: 'myspace.com',
        pinterest: 'pinterest.com',
        spotify: 'spotify.com',
        tumblr: 'tumblr.com',
        vimeo: 'vimeo.com',
        weibo: 'weibo.com',
        discord: 'discord.com',
        booking: 'booking.com',
        airbnb: 'airbnb.com',
        amazon: 'amazon.com',
        qzone: 'qzone.qq.com',
        adobe: 'adobe.com',
        mailru: 'mail.ru',
        wordpress: 'wordpress.com',
        imgur: 'imgur.com',
        disneyplus: 'disneyplus.com',
        netflix: 'netflix.com',
        archiveorg: 'archive.org',
        lazada: 'lazada.com',
        zoho: 'zoho.com',
        samsung: 'samsung.com',
        evernote: 'evernote.com',
        envato: 'envato.com',
        patreon: 'patreon.com',
        tokopedia: 'tokopedia.com',
        rambler: 'rambler.ru',
        quora: 'quora.com',
        atlassian: 'atlassian.com',
        canva: 'canva.com',
      };
      return domains[service] || `${service}.com`;
    },
    transformData(data) {
      const registeredComptes = [];
      const unregisteredComptes = [];

      // Copiez siteMappings dans une variable locale
      let localSiteMappings = Array.isArray(this.siteMappings) ? this.siteMappings : [];

      // Vérifiez que siteMappings est bien un tableau, sinon transformez-le en tableau
      if (!Array.isArray(localSiteMappings)) {
        console.error('siteMappings is not an array or not defined:', localSiteMappings);
        logErrorToDiscord('siteMappings is not an array or not defined:', localSiteMappings);

        // Transformez siteMappings en tableau si ce n'est pas le cas
        localSiteMappings = localSiteMappings ? [localSiteMappings] : [];
      }

      data.forEach(entry => {
        const contactInfo = Object.keys(entry)[0];
        const accounts = entry[contactInfo];

        for (const [service, details] of Object.entries(accounts)) {
          // console.log(service);

          const siteMapping = localSiteMappings.find(site => site.name && typeof site.name === 'string' && site.name.toLowerCase() === service.toLowerCase());
          const categories = siteMapping ? siteMapping.categories : ['Service en ligne'];

          const compte = {
            nom: service.charAt(0).toUpperCase() + service.slice(1),
            type: categories.join(', '),
            email: contactInfo,
            action: 'Suppression',
            logo: `https://logo.clearbit.com/${service.toLowerCase()}.com`,
            registered: details.registered
          };

          if (details.registered) {
            registeredComptes.push(compte);
          } else {
            unregisteredComptes.push(compte);
          }
        }
      });

      return { registeredComptes, unregisteredComptes };
    },
    scanRapide() {
      if (!this.siteMappingsReady) {
        console.error('siteMappings not ready yet');
        return;
      }
      this.loading = true;
      this.$emit('clear-comptes');
      this.$emit('scan-en-cours', true);

      const email = this.localEmail;
      const telephone = this.localPhone;
      const id = this.idDossier;

      console.log("email ", email);
      console.log("telephone ", telephone);
      console.log("id ", id);

      fetch('https://api.reposdigital.fr/airtable/scan-rapide-funecap', {
        method: 'POST',
        body: JSON.stringify({
          stringEmail: email,
          numeroTel: this.formatFrenchPhoneNumber(telephone),
          idDossier: id,
          decision: "Suppression"
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          const { registeredComptes, unregisteredComptes } = this.transformData(data);
          this.$emit('update-comptes', { registeredComptes, unregisteredComptes });
        })
        .catch(error => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.loading = false;
          this.$emit('scan-en-cours', false);
        });
    }
  }
}
</script>


<style scoped>
.info-form-container {
  padding: 19px;
  border: 1px solid #EAECF0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fff;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* width: 80%; Définir la largeur à 80% de l'espace horizontal disponible */
  max-width: 1200px;
  /* Ajouter une largeur maximale pour éviter qu'il ne devienne trop large */
  margin: 0 auto;

  /* Centrer le conteneur horizontalement */
  @media (min-width: 1000px) {
    width: 758px;
  }
}

h2 {
  font-size: 24px;
  margin-bottom: 30px;
  margin-top: 0px;
  font-weight: 500;
  /* Réduire l'épaisseur de la police */
  color: #101828;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 1px;
  color: #344054;
  font-weight: 400;
  font-size: 14px;
  /* Réduire la taille de la police des labels */
}

label span {
  font-weight: normal;
  color: #98A2B3;
  /* Changer la couleur du texte */
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Outfit', sans-serif;
  /* Assurer que la bonne police est utilisée */
}

input::placeholder {
  color: #D0D5DD;
}

.phone-input {
  display: flex;
  align-items: center;
}

.phone-input select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  background-color: #f9f9f9;
  flex: 0 0 100px;
  /* Fixer la largeur du select */
}

.phone-input input {
  flex: 1;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
}

.email-input {
  display: flex;
  flex: 1;
}

button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #2E90FA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: 'Outfit', serif;
}

button:disabled {
  background-color: #D0D5DD;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #1570EF;
}

.loading-text {
  color: #475467;
  text-align: center;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
}

.lancer-button{
  display: flex;
  align-items: center;
  column-gap: 8px;
}
</style>
